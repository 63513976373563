import {
  EditActiveResponse,
  EditProposalQuantityResponse,
  Filter,
  GetProposalLocationDrilldownResponse,
  LocationPage,
  LocationSkusReceivingPage,
  LocationSkusSendingPage,
} from '@autone/openapi-rebalancing';
import { encodeUrl, ExtendedCustomBaseQueryType } from '@autone/ui';
import { FetchBaseQueryError } from '@reduxjs/toolkit/query';

import { EDIT_REBALANCING_TAGS } from '../../../constant';
import { SENDING } from '../../../features/edit-rebalancing/location-view/location-sidepanel/constants';
import { LocationDirectionType } from '../../../features/edit-rebalancing/location-view/location-sidepanel/types';
import { EnrichedLocationDrilldownProposal } from '../../../types';
import { addProductToFromLocationToFilters } from '../../../utils/features/productLocation/addProductToFromLocationToFilters';
import { addProductToFilters } from '../../../utils/features/products/addProductToFilters';
import {
  updateLocationAggregateCache,
  updateLocationDetailCache,
  updateLocationDrilldownCache,
} from '../../helpers/locations';
import { handleEditQuantity } from '../../helpers/shared/handleEditQuantity';
import { handleToggleEnable } from '../../helpers/shared/handleToggleEnable';
import { rebalancingApi } from '../rebalancing';

export const locationsDetailApi = rebalancingApi.injectEndpoints({
  endpoints: (builder) => ({
    getPagedLocationSkus: builder.query<
      LocationSkusSendingPage | LocationSkusReceivingPage,
      {
        batchId: string;
        locationId: string;
        locationDirection: LocationDirectionType;
        filters: Filter[];
        productSearchTerm?: string;
        includeNilTransfers: boolean;
        sortBy: string;
        cursor: string | null;
        limit: number;
      }
    >({
      providesTags: ['LocationDetailPaged'],
      query: ({
        batchId,
        locationId,
        locationDirection,
        filters,
        sortBy,
        cursor,
        limit,
        productSearchTerm,
        includeNilTransfers,
      }) => ({
        url: encodeUrl({
          url: `v2/batch/{batchId}/proposal/locations/{locationId}/skus?drilldown={locationDirection}&${
            cursor ? `cursor={cursor}&` : ''
          }limit={limit}&sort_by={sortBy}`,
          variables: {
            batchId,
            locationId,
            locationDirection,
            cursor: cursor || '',
            limit: `${limit}`,
            sortBy,
          },
        }),
        method: 'POST',
        body: {
          filters,
          product_search_term: productSearchTerm,
          include_nil_transfers: includeNilTransfers,
        },
      }),
    }),
    getLocationDetailDrilldown: builder.query<
      EnrichedLocationDrilldownProposal[],
      {
        batchId: string;
        locationId: string;
        productId: string;
        skuId: string;
        locationDirection: LocationDirectionType;
        filters: Filter[];
      }
    >({
      providesTags: ['LocationDetailDrilldown'],
      query: ({
        batchId,
        locationId,
        productId,
        locationDirection,
        filters,
      }) => ({
        url: encodeUrl({
          url: `batch/{batchId}/proposal/locations/{locationId}?drilldown={locationDirection}`,
          variables: {
            batchId,
            locationId,
            locationDirection,
          },
        }),
        method: 'POST',
        body: {
          filters: addProductToFilters({ activeFilters: filters, productId }),
        },
      }),
      transformResponse: (
        response: {
          proposals: EnrichedLocationDrilldownProposal[];
        },
        _meta,
        { skuId },
      ) => {
        response.proposals.forEach((proposal) => {
          proposal.user_quantity_sort = proposal.user_quantity;
          proposal.from_location_post_transfer_coverage_sort =
            proposal.from_location_post_transfer_coverage;
          proposal.to_location_all_trips_coverage_sort =
            proposal.to_location_all_trips_coverage;
        });

        return response.proposals.filter((d) => d.sku.sku_id === skuId);
      },
    }),
    patchLocationDetailStatus: builder.mutation<
      {
        data: null;
        error?: FetchBaseQueryError;
      },
      {
        batchId: string;
        locationId: string;
        locationDirection: LocationDirectionType;
        skuId: string;
        productId: string;
        globalFilters: Filter[];
        tableFilters: Filter[];
        enabled: boolean;
        rowsPerPage: number;
        serverSort: string;
        productSearchTerm: string;
        aggRowsPerPage: number;
        aggServerSort: string;
        aggTableSearchTerm: string;
        includeNilTransfers: boolean;
        isRowExpanded: boolean;
      }
    >({
      invalidatesTags: EDIT_REBALANCING_TAGS.filter(
        (tag) =>
          tag !== 'LocationsPaged' &&
          tag !== 'LocationDetailPaged' &&
          tag !== 'LocationDetailDrilldown',
      ),
      async queryFn(
        {
          batchId,
          locationId,
          locationDirection,
          skuId,
          productId,
          globalFilters,
          tableFilters,
          enabled,
          rowsPerPage,
          serverSort,
          productSearchTerm,
          aggRowsPerPage,
          aggServerSort,
          aggTableSearchTerm,
          includeNilTransfers,
          isRowExpanded,
        },
        { dispatch },
        _extraOptions,
        customBaseQuery: ExtendedCustomBaseQueryType<
          | EditActiveResponse
          | LocationSkusSendingPage
          | LocationSkusReceivingPage
          | LocationPage
          | GetProposalLocationDrilldownResponse
        >,
      ) {
        const { error } = await handleToggleEnable({
          customBaseQuery:
            customBaseQuery as ExtendedCustomBaseQueryType<EditActiveResponse>,
          batchId,
          body: {
            filters: tableFilters,
            enabled,
            location_id: locationId,
            sku_id: skuId,
          },
        });

        if (error) {
          return { error };
        }

        await updateLocationDetailCache({
          customBaseQuery: customBaseQuery as ExtendedCustomBaseQueryType<
            LocationSkusSendingPage | LocationSkusReceivingPage
          >,
          dispatch,
          batchId,
          locationId,
          locationDirection,
          filters: tableFilters,
          rowsPerPage,
          serverSort,
          productSearchTerm,
          includeNilTransfers,
          productId,
        });

        await updateLocationAggregateCache({
          customBaseQuery:
            customBaseQuery as ExtendedCustomBaseQueryType<LocationPage>,
          dispatch,
          batchId,
          filters: globalFilters,
          rowsPerPage: aggRowsPerPage,
          serverSort: aggServerSort,
          locationSearchTerm: aggTableSearchTerm,
          locationId,
        });

        if (!isRowExpanded)
          return {
            data: null,
          } as any;

        await updateLocationDrilldownCache({
          customBaseQuery:
            customBaseQuery as ExtendedCustomBaseQueryType<GetProposalLocationDrilldownResponse>,
          dispatch,
          batchId,
          filters: tableFilters,
          activeFilters: addProductToFilters({
            activeFilters: tableFilters,
            productId,
          }),
          locationId,
          locationDirection,
          productId,
          skuId,
        });

        return {
          data: null,
        } as any;
      },
    }),
    patchLocationDrilldownStatus: builder.mutation<
      {
        data: null;
        error?: FetchBaseQueryError;
      },
      {
        batchId: string;
        locationId: string;
        selectedLocationId: string;
        locationDirection: LocationDirectionType;
        skuId: string;
        productId: string;
        globalFilters: Filter[];
        tableFilters: Filter[];
        enabled: boolean;
        detailTableProps: {
          rowsPerPage: number;
          serverSort: string;
          searchInput: string;
          includeNilTransfers: boolean;
        };
        aggTableProps: {
          rowsPerPage: number;
          serverSort: string;
          searchInput: string;
        };
      }
    >({
      invalidatesTags: EDIT_REBALANCING_TAGS.filter(
        (tag) =>
          tag !== 'LocationsPaged' &&
          tag !== 'LocationDetailPaged' &&
          tag !== 'LocationDetailDrilldown',
      ),
      async queryFn(
        {
          batchId,
          locationId,
          selectedLocationId,
          locationDirection,
          skuId,
          productId,
          globalFilters,
          tableFilters,
          enabled,
          detailTableProps,
          aggTableProps,
        },
        { dispatch },
        _extraOptions,
        customBaseQuery:
          | ExtendedCustomBaseQueryType<EditActiveResponse>
          | ExtendedCustomBaseQueryType<
              LocationSkusSendingPage | LocationSkusReceivingPage
            >
          | ExtendedCustomBaseQueryType<GetProposalLocationDrilldownResponse>
          | ExtendedCustomBaseQueryType<LocationPage>,
      ) {
        const fromLocationId =
          locationDirection === SENDING ? selectedLocationId : locationId;
        const toLocationId =
          locationDirection === SENDING ? locationId : selectedLocationId;

        const { error } = await handleToggleEnable({
          customBaseQuery:
            customBaseQuery as ExtendedCustomBaseQueryType<EditActiveResponse>,
          batchId,
          body: {
            filters: globalFilters,
            enabled,
            from_location_id: fromLocationId,
            to_location_id: toLocationId,
            sku_id: skuId,
          },
        });

        if (error) {
          return { error };
        }

        await updateLocationDrilldownCache({
          customBaseQuery:
            customBaseQuery as ExtendedCustomBaseQueryType<GetProposalLocationDrilldownResponse>,
          dispatch,
          batchId,
          filters: tableFilters,
          activeFilters: addProductToFromLocationToFilters({
            activeFilters: tableFilters,
            productId,
            locationDirection,
            locationId,
          }),
          locationId: selectedLocationId,
          locationDirection,
          productId,
          skuId,
        });

        const { rowsPerPage, serverSort, searchInput, includeNilTransfers } =
          detailTableProps;

        await updateLocationDetailCache({
          customBaseQuery: customBaseQuery as ExtendedCustomBaseQueryType<
            LocationSkusSendingPage | LocationSkusReceivingPage
          >,
          dispatch,
          batchId,
          locationId: selectedLocationId,
          locationDirection,
          filters: tableFilters,
          rowsPerPage,
          serverSort,
          productSearchTerm: searchInput,
          includeNilTransfers,
          productId,
        });

        const {
          rowsPerPage: aggRowsPerPage,
          serverSort: aggServerSort,
          searchInput: aggTableSearchTerm,
        } = aggTableProps;

        await updateLocationAggregateCache({
          customBaseQuery:
            customBaseQuery as ExtendedCustomBaseQueryType<LocationPage>,
          dispatch,
          batchId,
          filters: globalFilters,
          rowsPerPage: aggRowsPerPage,
          serverSort: aggServerSort,
          locationSearchTerm: aggTableSearchTerm,
          locationId: selectedLocationId,
        });

        return {
          data: null,
        } as any;
      },
    }),
    patchTransferQuantity: builder.mutation<
      {
        data: null;
        error?: FetchBaseQueryError;
      },
      {
        batchId: string;
        locationId: string;
        selectedLocationId: string;
        locationDirection: LocationDirectionType;
        skuId: string;
        productId: string;
        globalFilters: Filter[];
        tableFilters: Filter[];
        detailTableProps: {
          rowsPerPage: number;
          serverSort: string;
          searchInput: string;
          includeNilTransfers: boolean;
        };
        aggTableProps: {
          rowsPerPage: number;
          serverSort: string;
          searchInput: string;
        };
        quantity: number;
      }
    >({
      invalidatesTags: EDIT_REBALANCING_TAGS.filter(
        (tag) =>
          tag !== 'LocationsPaged' &&
          tag !== 'LocationDetailPaged' &&
          tag !== 'LocationDetailDrilldown',
      ),
      async queryFn(
        {
          batchId,
          locationId,
          selectedLocationId,
          locationDirection,
          skuId,
          productId,
          tableFilters,
          globalFilters,
          detailTableProps,
          aggTableProps,
          quantity,
        },
        { dispatch },
        _extraOptions,
        customBaseQuery:
          | ExtendedCustomBaseQueryType<
              LocationSkusSendingPage | LocationSkusReceivingPage
            >
          | ExtendedCustomBaseQueryType<GetProposalLocationDrilldownResponse>
          | ExtendedCustomBaseQueryType<LocationPage>
          | ExtendedCustomBaseQueryType<EditProposalQuantityResponse>,
      ) {
        const fromLocationId =
          locationDirection === SENDING ? selectedLocationId : locationId;
        const toLocationId =
          locationDirection === SENDING ? locationId : selectedLocationId;

        const { data: quantityData, error } = await handleEditQuantity({
          customBaseQuery:
            customBaseQuery as ExtendedCustomBaseQueryType<EditProposalQuantityResponse>,
          batchId,
          body: {
            from_location_id: fromLocationId,
            to_location_id: toLocationId,
            sku_id: skuId,
            quantity,
          },
        });

        if (error) return { data: quantityData, error };

        await updateLocationDrilldownCache({
          customBaseQuery:
            customBaseQuery as ExtendedCustomBaseQueryType<GetProposalLocationDrilldownResponse>,
          dispatch,
          batchId,
          filters: tableFilters,
          activeFilters: addProductToFromLocationToFilters({
            activeFilters: tableFilters,
            productId,
            locationDirection,
            locationId,
          }),
          locationId: selectedLocationId,
          locationDirection,
          productId,
          skuId,
        });

        const { rowsPerPage, serverSort, searchInput, includeNilTransfers } =
          detailTableProps;

        await updateLocationDetailCache({
          customBaseQuery: customBaseQuery as ExtendedCustomBaseQueryType<
            LocationSkusSendingPage | LocationSkusReceivingPage
          >,
          dispatch,
          batchId,
          locationId: selectedLocationId,
          locationDirection,
          filters: tableFilters,
          rowsPerPage,
          serverSort,
          productSearchTerm: searchInput,
          includeNilTransfers,
          productId,
        });

        const {
          rowsPerPage: aggRowsPerPage,
          serverSort: aggServerSort,
          searchInput: aggTableSearchTerm,
        } = aggTableProps;

        await updateLocationAggregateCache({
          customBaseQuery:
            customBaseQuery as ExtendedCustomBaseQueryType<LocationPage>,
          dispatch,
          batchId,
          filters: globalFilters,
          rowsPerPage: aggRowsPerPage,
          serverSort: aggServerSort,
          locationSearchTerm: aggTableSearchTerm,
          locationId: selectedLocationId,
        });

        return {
          data: null,
        } as any;
      },
    }),
  }),
});

export const {
  useGetPagedLocationSkusQuery,
  useGetLocationDetailDrilldownQuery,
  useLazyGetPagedLocationSkusQuery,
  usePatchLocationDetailStatusMutation,
  usePatchTransferQuantityMutation,
  usePatchLocationDrilldownStatusMutation,
} = locationsDetailApi;
