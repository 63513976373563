import { customBaseQuery, isFeatureSwitchEnabled } from '@autone/ui';
import { createApi } from '@reduxjs/toolkit/query/react';

import { EDIT_REBALANCING_TAGS } from '../../constant';

// Define a service using the base rebalancing URL and expected endpoints
export const rebalancingApi = createApi({
  reducerPath: 'rebalancingApi',
  tagTypes: ['Batch', 'Batches', 'NetworkTrips', ...EDIT_REBALANCING_TAGS],
  baseQuery: async (args, api, extraOptions) => {
    // custom query adds auth headers and error handling
    const isV2Enabled = await isFeatureSwitchEnabled({
      key: 'rebalancing-api-v2',
      defaultValue: true,
    });
    const uri = isV2Enabled ? 'v2/rebalancing' : 'rebalancing';
    return customBaseQuery(args, api, extraOptions, uri);
  },
  endpoints: () => ({}),
});
